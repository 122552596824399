.bonus {
  border: 1px solid var(--main-accent-bg-color);
  border-radius: 10px;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  &__title {
    font-size: 2rem;
    text-align: center;
    color: var(--main-accent-bg-color);
  }

  &__value {
    margin: 2rem 0;
    font-size: 4rem;
    line-height: 4.5rem;
    font-weight: 600;
    text-align: center;
    color: var(--main-accent-bg-color);
  }

  &__text {
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 500;
    text-align: center;
    color: #000000;
    margin-bottom: 2rem;
  }

  &__button {
    margin-top: auto;
    padding: 1rem 3rem;
    border: 1px solid #000000;
    border-radius: 5px;
    background-color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
    transition: 250ms all;
    text-decoration: none;

    &:hover {
      background-color: var(--main-accent-bg-color);
      border: 1px solid var(--main-accent-bg-color);
      color: #ffffff;
    }
  }

  &.accented {
    background-color: var(--main-accent-bg-color);

    .bonus__title, .bonus__value, .bonus__text {
      color: #ffffff;
    }

    .bonus__button {
      background-color: transparent;
      border: 1px solid #ffffff;
      color: #ffffff;

      &:hover {
        background-color: #ffffff;
        border: 1px solid #ffffff;
        color: var(--main-accent-bg-color);
      }
    }
  }
}
