.rating {
  padding: 1rem 0;

  &__items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__item {
    font-size: 1.3rem;
    padding: 0 0.2rem;
    color: #888888;
  }
}
