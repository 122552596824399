.video {
  max-width: 650px;

  &.align-center {
    margin: 0 auto;
  }

  &.align-right {
    margin-left: auto;
  }

  &__container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    background-color: #000000;
  }

  &__preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: none;
    border: none;

    &-shape {
      fill: #212121;
      fill-opacity: 0.8;
    }

    &-icon {
      fill: #ffffff;
    }

    &:focus {
      outline: none;
    }

    &:hover &-shape, &:focus &-shape {
      fill: #ff0000;
      fill-opacity: 1;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
