.page-header {
  background-color: var(--header-bg-color);

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;

    @media (max-width: 1024px) {
      justify-content: space-between;
      padding: var(--offsets) 0;
      position: relative;
    }
  }

  &__logo {
    max-width: 150px;
    width: 100%;

    @media (max-width: 1024px) {
      max-width: 100px;
    }
  }

  &__nav {
    margin: 0 auto;

    @media (max-width: 1024px) {
      order: 3;
    }
  }

  &__buttons {
    margin: 0 auto;

    a{
      font-weight: 400;
    }

    @media (max-width: 1024px) {
      order: 2;
    }
    @media (max-width: 550px) {
      margin: 0 -0.2rem;
    }
    @media (max-width: 500px) {
      width: 100%;
      order: 5;
      text-align: center;
      margin-top: 1rem;
    }

    button,
    a{
      margin: 1rem;
      @media (max-width: 550px) {
        margin: 0 0.2rem;
      }
    }
  }

  &__burger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 35px;
    height: 25px;

    @media (max-width: 1024px) {
      display: flex;
      order: 3;
    }

    span {
      display: block;
      height: 3px;
      width: 100%;
      background-color: var(--main-accent-bg-color);
      border-radius: 20%;
      transform-origin: center;
      transition: 250ms all;
    }

    &.active {
      span {
        &:nth-of-type(1) {
          transform: translateY(11px) rotateZ(45deg);
        }
        &:nth-of-type(2) {
          transform: scaleX(0);
        }
        &:nth-of-type(3) {
          transform: translateY(-11px) rotateZ(-45deg);
        }
      }
    }
  }
}
