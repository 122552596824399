.show-posts {
  background: #fbfbfb;

  &__item {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    padding-bottom: 10px;
    transition: 250ms;
    background-color: #fff;

    &:hover {
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);

      .show-posts__image img {
        transform: scale(1.1);
      }
    }
  }

  &__image {
    height: 150px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: 250ms all;
    }
  }

  &__title {
    color: var(--main-accent-bg-color);
    margin-top: 1rem;
    padding: 0 10px;
    font-size: 1.2rem;
    line-height: 1.7rem;
  }

  &__text {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0 10px;
  }

  &__link {
    text-decoration: underline;
    color: var(--main-accent-bg-color);
    margin-top: auto;
    padding: 0 10px;
  }

  &__hidden-link {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}
