.shared-items {
  margin: 2rem 0;

  &__title {
    margin: 15px 10px;
    font-size: 1.2rem;
    font-weight: 600;
  }

  &__list {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -2px;
    padding-left: 0;
  }

  &__item {
    width: 100%;
    flex: 1 1 150px;
    margin: 2px;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    box-sizing: border-box;
    margin: 0 0.5rem;
    background-color: #f1f1f1;
    border-radius: 10px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
}