.page-404 {

  &__title {
    text-align: center;
    font-size: 5rem;
    margin-top: 2rem;
  }

  &__subtitle {
    text-align: center;
    font-size: 2rem;
    font-weight: 300;
    margin-top: 2rem;
  }

  &__link {
    margin: 2rem 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
