.slots {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  height: 100%;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000000;
      opacity: 0.5;
    }
  }

  &__title {
    color: #ffffff;
  }

  & &__button {
    font-weight: 300;
    width: 80%;
    padding: 0.3rem 1rem;
  }

  &__text {
    color: #ffffff;
    font-size: 0.9rem;
    text-align: center;
    backdrop-filter: blur(5px);
    border-radius: 5px;
  }
}
