.container {
  @include container;
}

.container-full {
  @include container-full;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-top: 1rem;
}

a {
  font-size: 1rem;
  font-weight: 400;
  color: var(--main-accent-bg-color);

  &:hover {
    text-decoration: none;
  }
}

ul, ol {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  padding-left: 25px;
  margin-top: 1rem;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.3rem;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.1rem;
  margin-bottom: 1rem;
}

h4 {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.9rem;
  margin-bottom: 1rem;
}

h5 {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.7rem;
  margin-bottom: 1rem;
}

h6 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.wrapper > {
  h1, h2, h3, h4, h5, h6, p, .wp-block-table, ul, ol, blockquote {
    padding: 0 var(--offsets);
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }

  ul, ol {
    padding-left: 50px;
  }
}

.wrapper blockquote, blockquote {
  padding: 1rem var(--offsets) 1rem 2rem;
  border-left: 5px solid var(--main-accent-bg-color);
  background-color: aliceblue;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-top: 1rem;
}

.wrapper > :last-child {
  margin-bottom: 2rem;
}

table {
  border: 1px solid var(--main-accent-bg-color);
  margin-top: 1rem;

  th {
    padding: 0.5rem 1rem;
    text-align: center;
    background-color: var(--main-accent-bg-color);
    color: #ffffff;
  }

  td {
    padding: 0.5rem 1rem;
    border: 1px solid var(--main-accent-bg-color);
  }
}

.wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}