.scroll-top {
  position: fixed;
  bottom: 70px;
  right: 15px;

  &__button {
    appearance: none;
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--main-accent-bg-color);
    color: #ffffff;
    text-shadow: 1px 1px 2px black;
    font-size: 20px;
  }
}