.columns {
  padding: 2rem 0;

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: -1rem;

    &.reversed {
      flex-direction: row-reverse;
    }
  }

  &__item {
    margin: 1rem;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  &.align-center {
    & .columns__container {
      align-items: center;
    }
  }

  &.align-top {
    & .columns__container {
      align-items: flex-start;
    }
  }

  &.align-bottom {
    & .columns__container {
      align-items: flex-end;
    }
  }

  &.align-stretch {
    & .columns__container {
      align-items: stretch;
    }
  }

  &.col-1 {
    .columns__item {
      width: calc(100% - 2rem);
    }
  }

  &.col-2 {
    .columns__item {
      width: calc(100% / 2 - 2rem);

      @media (max-width: 800px) {
        width: calc(100% - 2rem);
      }
    }
  }

  &.col-3 {
    .columns__item {
      width: calc(100% / 3 - 2rem);

      @media (max-width: 1000px) {
        width: calc(100% / 2 - 2rem);
      }
      @media (max-width: 750px) {
        width: calc(100% - 2rem);
      }
    }
  }

  &.col-4 {
    .columns__item {
      width: calc(100% / 4 - 2rem);

      @media (max-width: 1000px) {
        width: calc(100% / 3 - 2rem);
      }
      @media (max-width: 750px) {
        width: calc(100% / 2 - 2rem);
      }
      @media (max-width: 550px) {
        width: calc(100% - 2rem);
      }
    }
  }

  &.col-5 {
    .columns__item {
      width: calc(100% / 5 - 2rem);

      @media (max-width: 1200px) {
        width: calc(100% / 4 - 2rem);
      }
      @media (max-width: 1000px) {
        width: calc(100% / 3 - 2rem);
      }
      @media (max-width: 750px) {
        width: calc(100% / 2 - 2rem);
      }
      @media (max-width: 550px) {
        width: calc(100% - 2rem);
      }
    }
  }

  &.col-6 {
    .columns__item {
      width: calc(100% / 6 - 2rem);

      @media (max-width: 1300px) {
        width: calc(100% / 5 - 2rem);
      }
      @media (max-width: 1200px) {
        width: calc(100% / 4 - 2rem);
      }
      @media (max-width: 1000px) {
        width: calc(100% / 3 - 2rem);
      }
      @media (max-width: 750px) {
        width: calc(100% / 2 - 2rem);
      }
      @media (max-width: 550px) {
        width: calc(100% - 2rem);
      }
    }
  }
}

.wp-block-columns {
  max-width: calc(1380px + var(--offsets) + var(--offsets));
  padding: 2rem var(--offsets);
  margin: 0 auto;
}

.wp-block-columns {
  display: grid;
  grid-gap: 30px;
  margin-bottom: 0;

  .wp-block-column {
    margin: 0;
  }
}

.wp-block-columns {

  &.col-1 {
    grid-template-columns: repeat(1, 1fr);
  }

  &.col-2 {
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 750px) {
      grid-template-columns: 1fr;
    }
  }

  &.col-3 {
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 750px) {
      grid-template-columns: 1fr;
    }
  }

  &.col-4 {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 1050px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 750px) {
      grid-template-columns: 1fr;
    }
  }

  &.col-5 {
    grid-template-columns: repeat(5, 1fr);

    @media (max-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 1050px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 750px) {
      grid-template-columns: 1fr;
    }
  }

  &.col-6 {
    grid-template-columns: repeat(6, 1fr);

    @media (max-width: 1300px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 1050px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 750px) {
      grid-template-columns: 1fr;
    }
  }
}

.wp-block-column {
  display: flex;
  flex-direction: column;

  & > * {
    flex: 1 1;

    &:not(:first-child) {
      margin-top: 30px;
    }
  }

  & .row {
    padding: 0;
  }
}