:root {
  --main-accent-bg-color: #2ecc71;
  --main-nav-color: #ffffff;
  --header-bg-color: #000000;
  --footer-bg-color: #000000;
  --content-button-bg-color: #2ecc71;
  --content-button-color: #ffffff;
  --offsets: 30px;
}

@media screen and (max-width: 1200px){
  :root {
    --offsets: 20px;
  }
}
@media screen and (max-width: 900px){
  :root {
    --offsets: 16px;
  }
}

@mixin container {
  max-width: 1440px;
  padding: 0 var(--offsets);
  margin: 0 auto;
}
@mixin container-full {
  max-width: 100%;
}
