.mobile-button {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  a{
    font-weight: 400;
    font-size: 1.2rem;
  }

  @media (max-width: 700px) {
    display: block;
  }

  &__container {
    padding: 10px 1rem;
  }

  &__control {
    display: block;
    width: 100%;
  }
}
