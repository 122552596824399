.main-nav {
  align-self: stretch;

  @media (max-width: 1024px) {
    margin-left: calc(-100vw / 2 + 100% / 2) !important;
    margin-right: calc(-100vw / 2 + 100% / 2) !important;
    max-width: 100vw !important;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--header-bg-color);
    opacity: 0;
    pointer-events: none;
    transition: 250ms opacity;

    &.active {
      opacity: 1;
      pointer-events: auto;
      z-index: 9999;
    }
  }

  &__list {
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
    }

    & > li {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 1024px) {
        width: 100%;
      }

      &:hover {
        & > a::after {
          transform: scaleX(1);
        }

        & .sub-menu {
          opacity: 1;
          pointer-events: auto;
        }
      }

      & > span.active__link{
        color: grey;
      }
      & > a,
      & > span{
        font-size: 1.2rem;
        text-decoration: none;
        font-weight: 300;
        margin: 0 0.5rem;
        padding: 0.5rem;
        box-sizing: border-box;
        position: relative;
        color: var(--main-nav-color);

        @media (max-width: 1024px) {
          display: block;
          width: 100%;
          padding: 0.5rem 0;
          margin: 0;
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: var(--main-accent-bg-color);
          transform: scaleX(0);
          transition: 250ms;

          @media (max-width: 1024px) {
            display: none;
          }
        }
      }
    }
  }

  .menu-item-has-children {
    position: relative;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .sub-menu__arrow {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-35%);
      right: -11px;
      padding: 0 0 0 40px;
      text-align: right;

      @media (min-width: 1024px) {
          pointer-events: none;
      }

      @media (max-width: 1024px) {
        right: 0;
        top: 0;
        transform: none;
        pointer-events: auto;
      }

      svg {
        max-width: 15px;
        width: 100%;
        height: auto;
        fill: var(--main-accent-bg-color);
        transition: 250ms all;
      }
    }

    &.active {

      & .sub-menu__arrow svg {
        transform: rotateZ(180deg);
      }

      & .sub-menu {
        display: block;
        position: relative;
        opacity: 1;
        pointer-events: auto;
        top: 0;
        margin-top: 0;
        padding: 0 1rem;

        &::before {
          display: none;
        }
      }
    }
  }

  .sub-menu {
    $sub-menu-offset: 0.5rem;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: $sub-menu-offset;
    padding: 0.5rem 1rem;
    list-style: none;
    background-color: var(--header-bg-color);
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    transition: 250ms all;
    z-index: 100;

    @media (max-width: 1024px) {
      display: none;
    }

    @media (max-width: 650px){
      max-width: 100%;
    }

    &::before {
      content: "";
      position: absolute;
      top: -$sub-menu-offset;
      left: 0;
      height: $sub-menu-offset;
      width: 100%;
      background-color: transparent;
    }

    a {
      display: block;
      font-size: 1rem;
      text-decoration: none;
      color: var(--main-nav-color);
      margin: 0.5rem 0;
      white-space: nowrap;

      @media (max-width: 1024px) {
        font-size: 1.2rem;
        font-weight: 300;
      }
      @media (max-width: 650px){
        font-size: 1rem;
      }
    }


      &:hover {
        color: var(--main-accent-bg-color);
      }
    }
  }
