.author-block {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem 0;

  &_outlined {
    border: 1px solid var(--main-accent-bg-color);
    padding: 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0 1rem 0;
  }

  &__avatar {
    width: 60px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &_scaled {
      width: 150px;
      height: 150px;
    }
  }

  &__content {
    margin-left: 1rem;
    max-width: 600px;
  }

  &__name {
    font-size: 1rem;
    font-weight: 600;
  }

  &__description {
    font-size: .9rem;
    font-weight: 300;
    opacity: 0.8;
    margin-top: 0.5rem;
  }

  &__social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    padding-left: 0;
    margin: 0.5rem -5px -5px;

    li {
      width: 25px;
      height: 25px;
      margin: 5px;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}