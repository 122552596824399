.faq {

  &__item {
    position: relative;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 10px;
  }

  &__toggle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &__question {
    margin: 0;
    cursor: pointer;
  }

  &__answer {
    height: auto;
    max-height: 0;
    overflow: hidden;
    margin: 0;

    &.active {
      max-height: 1000px;
      margin-top: 1rem;
    }
  }
}
