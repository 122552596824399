.review {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &__text {
    margin-top: 0;
    color: rgba(33, 33, 33, 0.7);
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  &__user {
    margin-top: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-image {
      max-width: 60px;
      border-radius: 50%;
    }

    &-name {
      margin-top: 0;
      margin-left: 1rem;
      color: #6ec1e4;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
}
