.advantages {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  height: 100%;

  &__image {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    margin-top: 1rem;
  }
}
