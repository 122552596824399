.breadcrumbs {
  padding: 0.5rem 0;

  &__list {
    list-style: none;
    margin-top: 0;
    padding: 0 30px;
    display: flex;
  }

  &__item {
    margin-right: 10px;
  }

  &__link, &__current {
    font-size: 0.9rem;
    font-weight: 300;
    text-decoration: none;
    color: var(--main-accent-bg-color);
  }

  &__separator {
    color: var(--main-accent-bg-color);
  }
}
