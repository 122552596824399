.icon-blocks {
  padding: 2rem 0;

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  &__item {
    background-color: var(--main-accent-bg-color);
    width: calc(100% / 6);
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid #ffffff;

    @media (max-width: 1000px) {
      width: calc(100% / 3);
    }
    @media (max-width: 650px) {
      width: calc(100% / 2);
    }

    &:hover {

      .icon-blocks__image {
        transform: translateY(-10px);
      }
    }
  }

  &__image {
    text-align: center;
    margin-top: 2rem;
    transition: 250ms all;

    img {
      max-width: 100px;
    }
  }

  &__content {
    text-align: center;
    margin: 1rem 0 0;
    color: #ffffff;
    font-size: 0.9rem;
  }
}
