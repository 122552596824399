.toc {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  background-color: #f3f4f4;
  border-radius: 10px;

  &.active {
    .toc__list {
      max-height: 1000px;
      margin-top: 1rem;
    }

    .toc__title::after {
      transform: translateY(-30%) rotateZ(180deg);
    }
  }

  &__title {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0;
    color: #333333;
    display: inline-block;
    padding-right: 15px;
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-30%);
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8.7px 5px 0 5px;
      border-color: var(--main-accent-bg-color) transparent transparent transparent;
      transition: 250ms all;
    }
  }

  &__list {
    margin-top: 0;
    height: auto;
    max-height: 0;
    overflow: hidden;
  }

  &__link {
    display: inline-block;
    color: var(--main-accent-bg-color);
    padding: 0.3rem 0;
  }
}
