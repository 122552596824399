.comment-footer {
  margin-top: 2rem;
}

.comment-form {

  &-footer {
    margin: 3rem 0;
  }

  &__title {
    text-align: center;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px auto 0;
    max-width: 700px;
  }

  &__field {
    padding: 10px;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    outline: none;
    font-size: 1rem;
    font-weight: 300;
    transition: 250ms all;

    &:active, &:hover, &:focus {
      border: 1px solid var(--main-accent-bg-color);
    }

    &.error {
      border: 1px solid #dc3545;
      background-color: rgba(#dc3545, 0.05);
    }
  }

  &__input {
    width: calc(100% / 2 - 20px);
    margin: 10px;

    @media (max-width: 500px) {
      width: calc(100% - 20px);
    }
  }

  &__textarea {
    width: calc(100% - 20px);
    margin: 10px;
    min-height: 100px;
  }

  &__button {
    margin: 10px;

    @media (max-width: 500px) {
      width: calc(100% - 20px);
    }
  }

  &__alert {
    align-self: center;
    font-size: 0.8rem;
    font-weight: 300;
    color: #000;
    opacity: 0;
    transition: 250ms opacity;
    display: block;
    text-align: center;
    width: calc(100% - 20px);

    &.error {
      opacity: 1;
      color: #dc3545;
    }

    &.success {
      opacity: 1;
      color: #28a745;
    }
  }
}
