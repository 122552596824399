.page-footer {
  background-color: var(--header-bg-color);

  &__logo {

    @media (max-width: 550px) {
      text-align: center;
    }
  }


  &__nav {

  }

  &__list {
    margin-top: 0;

    a {
      font-size: 1.1rem;
      font-weight: 300;
      color: var(--main-nav-color);
      text-decoration: none;

      &:hover {
        color: var(--main-accent-bg-color);
      }
    }

    .sub-menu {
      margin: 0.2rem 0;
    }
  }

  &__text {
    color: var(--main-nav-color);
    font-weight: 300;
    font-size: 0.9rem;
    line-height: 1.4rem;
  }

  & .columns__item {
    & > * {
      margin-bottom: 1rem;
    }

    * {
      color: var(--main-nav-color);
    }
  }
}
