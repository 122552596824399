.comments {
  margin: 2rem -10px -10px;

  & .comment {
    margin: 10px;

    &__author {
      font-size: 1.2rem;
      font-weight: 500;
      display: inline-block;
      margin-bottom: 0;
    }

    &__date {
      font-size: 0.9rem;
      margin-left: 15px;
      color: #a2a2a2;
    }

    &__content {
      padding: 10px 0 0 10px;
      margin-left: 10px;
      border-left: 1px solid var(--main-accent-bg-color);
      margin-top: 0;
      font-size: 1rem;
      font-weight: 300;
    }

    &__karma {
      margin-left: 15px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      .karma__control {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        cursor: pointer;
        outline: none;
        border: none;
        width: 15px;
        height: 15px;
        line-height: 16px;
        border-radius: 50%;
        color: #ffffff;
        opacity: 0.1;

        &.decrease {
          background-color: #dc3545;
        }
        &.increase {
          background-color: #28a745;
        }

        &:hover {
          opacity: 1;
        }
      }

      .value {
        font-size: 1rem;
        font-weight: 300;
        color: #a2a2a2;
      }
    }

    &__reply {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      color: #888888;
    }

    &__child {
      padding-left: 20px;
      position: relative;
    }
  }
}
