.small-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  &__item {
    flex: 1 1 150px;
    padding: 1rem 2rem;
    position: relative;

    &:not(:last-of-type):after {
      content: "";
      position: absolute;
      top: calc(50% - 0.5rem);
      transform: translateY(-50%);
      right: 0;
      width: 1px;
      height: 40px;
      border-left: 1px dotted #cdcdcd;
    }
    
    a {
      text-decoration: none;
    }
  }

  &__image {
    width: 35px;
    height: 35px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #0083c3;
    box-sizing: border-box;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__text {
    text-align: center;
    margin-top: 5px;
    font-weight: 600;
    text-decoration: none;
    color: #0083c3;
    font-size: 1rem;
  }
}