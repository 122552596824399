.promocode {
  padding: 1rem 0;

  &__container {
    max-width: 670px;
    margin: 0 auto;
    background-color: #f5f5f5;
    padding: 1rem 2rem;
  }

  &__title {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6rem;
    text-align: center;
  }

  &__controls {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 3px;
    background-color: #fff;
    border-radius: 8px;

    @media (max-width: 600px) {
      flex-wrap: wrap;
    }
  }

  &__value {
    font-size: 2rem;
    font-weight: 700;
    margin-left: 10%;
  }

  &__button {
    font-size: 1rem;
    font-weight: 400;
    color: #ffffff;
    outline: none;
    text-decoration: none;
    text-align: center;
    padding: 0.6rem 1rem;
    width: 100%;
    max-width: 200px;
    margin-left: auto;
    border-radius: 7px;
    background-color: var(--main-accent-bg-color);
    border: 2px solid var(--main-accent-bg-color);
    cursor: pointer;


    &:hover {
      background-color: #fff;
      color: var(--main-accent-bg-color);
    }

    @media (max-width: 600px) {
      max-width: none;
      margin-top: 0.5rem;
    }
  }

  &__text {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;

    @media (max-width: 600px) {
      margin-left: 0;
      text-align: center;
    }
  }

  &__bottom {
    display: block;
    margin: 1.2rem auto 0;
    max-width: 300px;
    width: 100%;
    padding: 0.6rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    color: #ffffff;
    outline: none;
    text-align: center;
    text-decoration: none;
    background-color: #F23E71;
    border: 2px solid #F23E71;
    border-radius: 7px;
    cursor: pointer;

    &:hover {
      background-color: #fff;
      color: #F23E71;
    }
  }
}
